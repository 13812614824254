import ContentItem from 'components/Website/ContentItem';
import HorizontalLine from 'components/Website/HorizontalLine';
import PageTitle from 'components/Website/PageTitle';
import SiteContent from 'components/Website/SiteContent';
import WysiwygContent from 'components/WysiwygContent';
import useFetchResource from 'hooks/useFetchResource';
import api from 'libs/api';
import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import Attachments from 'components/Attachments';
import { useParams } from 'react-router-dom';

export interface ICustomPage {
  content: string;
  title: string;
  slug: string;
  files: File[];
  policy: {
    can_read: boolean;
  };
}

const CustomPage: FunctionComponent = () => {
  const { slug } = useParams();
  const {
    resource: { title, content, files },
    fetchResource,
  } = useFetchResource<ICustomPage>({
    api: api.website.public.getOne,
    initialParams: {
      slug,
    },
    initialValues: {
      content: '',
      policy: {
        can_read: true,
      },
      slug: '',
      title: '',
      files: [],
    },
    useNotFound: true,
    initialLoad: false,
  });

  useEffect(() => {
    const fetchPage = () => fetchResource({ slug });
    fetchPage();
  }, [slug]);

  return (
    <SiteContent>
      <ContentItem>
        <PageTitle>{title}</PageTitle>
      </ContentItem>
      <ContentItem>
        <HorizontalLine />
      </ContentItem>
      <WysiwygContent>
        <ContentItem dangerouslySetInnerHTML={{ __html: content }} />
      </WysiwygContent>
      {files.length > 0 && (
        <ContentAttachments>
          <Attachments label="Attachments" files={files} />
        </ContentAttachments>
      )}
    </SiteContent>
  );
};

export default CustomPage;

const ContentAttachments = styled(ContentItem)`
  p {
    font-weight: bold;
    padding-bottom: 0;
  }
`;
