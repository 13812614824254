import ScrollToButton from 'components/ScrollToButton';
import Content from 'components/Website/Content';
import ContentItem from 'components/Website/ContentItem';
import Header from 'components/Website/Header';
import HeaderTitle from 'components/Website/HeaderTitle';
import WysiwygContent from 'components/WysiwygContent';
import useAccount from 'hooks/useAccount';
import useFetchResource from 'hooks/useFetchResource';
import api from 'libs/api';
import { PROJECT_TITLE } from 'libs/constants';
import { ICustomPage } from 'pages/Website/CustomPage';
import React, { FunctionComponent } from 'react';
import { useTheme } from 'theme/ThemeContext';
import siteBackgroundImage from 'images/site-background.jpg';
import Attachments from 'components/Attachments';
import styled from 'styled-components';

const HomePage: FunctionComponent = () => {
  const { account } = useAccount();
  const theme = useTheme();
  const {
    resource: { content, files },
  } = useFetchResource<ICustomPage>({
    api: api.website.public.getOne,
    initialParams: {
      slug: 'home',
    },
    initialValues: {
      content: '',
      policy: {
        can_read: true,
      },
      slug: '',
      title: '',
      files: [],
    },
  });

  return (
    <>
      <Header
        siteBackground={
          theme.backgroundImage
            ? `${process.env.REACT_APP_IMAGE_PREFIX}${theme.backgroundImage}`
            : siteBackgroundImage
        }
      >
        <HeaderTitle>Welcome to {account.name || PROJECT_TITLE}</HeaderTitle>
      </Header>
      <Content>
        <ScrollToButton href="#main" />
        <WysiwygContent>
          <ContentItem id="main" dangerouslySetInnerHTML={{ __html: content }} />
        </WysiwygContent>
        {files.length > 0 && (
          <ContentAttachments>
            <Attachments label="Attachments" files={files} />
          </ContentAttachments>
        )}
      </Content>
    </>
  );
};

export default HomePage;

const ContentAttachments = styled(ContentItem)`
  p {
    font-weight: bold;
    padding-bottom: 0;
  }
`;
