import { IAdministrationPageCard } from 'components/AdministrationCards/PageCard';
import { ReactComponent as AccountSettings } from 'icons/administration/account_settings.svg';
import { ReactComponent as AmenityBookings } from 'icons/administration/amenity_bookings.svg';
import { ReactComponent as Building } from 'icons/administration/building.svg';
import { ReactComponent as GlobalSettings } from 'icons/administration/global_settings.svg';
import { ReactComponent as Groups } from 'icons/administration/groups.svg';
import { ReactComponent as Invoices } from 'icons/administration/invoices.svg';
import { ReactComponent as Log } from 'icons/administration/log.svg';
import { ReactComponent as Maintenance } from 'icons/administration/maintenance.svg';
import { ReactComponent as Notices } from 'icons/administration/notices.svg';
import { ReactComponent as Plan } from 'icons/administration/plan_and_billing.svg';
import { ReactComponent as Projects } from 'icons/administration/projects.svg';
import { ReactComponent as Reports } from 'icons/administration/reports.svg';
import { ReactComponent as TaskCategories } from 'icons/administration/task_categories.svg';
import { ReactComponent as UserResidentIcon } from 'icons/administration/user_and_residents.svg';
import { ReactComponent as AnalyticsIcon } from 'icons/administration/analytics.svg';

export const administrationPages: IAdministrationPageCard[] = [
  {
    dataTestLink: 'administration-users',
    description:
      'Manage your user and resident information. Approve users, specify access, view user details.',
    icon: UserResidentIcon,
    iconFillingRule: [1, 1, 1, 1, 1],
    link: '/administration/users',
    permission: 'users_accounts',
    permissionTypes: ['read'],
    title: 'User and Residents',
  },
  {
    dataTestLink: 'amenity-bookings',
    description: 'View, edit and approve bookings for your amenities.',
    icon: AmenityBookings,
    iconFillingRule: [1, 0, 0, 0, 0, 1, 1],
    link: '/amenities/bookings',
    permission: 'amenities',
    permissionTypes: ['read', 'edit'],
    title: 'Amenity bookings',
  },
  {
    dataTestLink: 'reports',
    description: 'View and print various reports.',
    icon: Reports,
    iconFillingRule: [0, 0, 0, 0, 0, 1, 1],
    link: '/administration/reports',
    permission: 'reports',
    permissionTypes: ['read'],
    title: 'Reports',
  },
  {
    dataTestLink: 'global-settings',
    description:
      'Maintain settings available through your account, such as document categories, enabled features',
    icon: GlobalSettings,
    iconFillingRule: [1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1],
    link: '/administration/global_settings',
    permission: 'global_settings',
    permissionTypes: ['read', 'edit'],
    title: 'Global settings',
  },
  {
    dataTestLink: 'website-maintenance',
    description: 'Create and edit website pages, specify header image.',
    icon: Maintenance,
    iconFillingRule: [0, 0, 0, 0, 1],
    link: '/administration/website/pages',
    permission: 'website_maintenance',
    permissionTypes: ['read', 'edit'],
    title: 'Website maintenance',
  },
  {
    dataTestLink: 'notices',
    description:
      'Add notices, messages or policies when posting to the message forum, classifieds or the contact page.',
    icon: Notices,
    iconFillingRule: [1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1],
    link: '/administration/notices',
    permission: 'notices',
    permissionTypes: ['read', 'edit'],
    title: 'Notices',
  },
  {
    dataTestLink: 'groups',
    description: 'Manage user groups, specify feature access, and add users to groups.',
    icon: Groups,
    iconFillingRule: [1, 1, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 0, 1, 0, 0, 0, 1],
    link: '/administration/groups',
    permission: 'user_groups',
    permissionTypes: ['read'],
    title: 'User Groups',
  },
  {
    dataTestLink: 'projects',
    description: 'Create projects so you can group relevant Tasks together',
    icon: Projects,
    iconFillingRule: [0, 1],
    link: '/administration/projects',
    permission: 'projects',
    permissionTypes: ['read'],
    title: 'Projects',
  },
  {
    dataTestLink: 'task-categories',
    description: 'Create and manage categories for Tasks',
    icon: TaskCategories,
    iconFillingRule: [0, 0, 1, 0, 0],
    link: '/administration/task/categories',
    permission: 'task_categories',
    permissionTypes: ['read', 'edit'],
    title: 'Task Categories',
  },
  {
    dataTestLink: 'assignment-stats',
    description: 'Get the statistics of your condominium.',
    icon: AnalyticsIcon,
    iconFillingRule: [0],
    link: '/administration/analytics',
    permission: 'analytics',
    permissionTypes: ['read'],
    title: 'Analytics',
  },
  {
    dataTestLink: 'logs',
    description: 'View your account activity',
    icon: Log,
    iconFillingRule: [1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    link: '/administration/log',
    permission: 'event_log',
    permissionTypes: ['read'],
    title: 'Log',
  },
  {
    dataTestLink: 'buildings',
    description: 'Create and manage your account buildings',
    icon: Building,
    iconFillingRule: [0, 1],
    link: '/administration/buildings',
    permission: 'buildings',
    permissionTypes: ['read', 'edit'],
    title: 'Buildings',
  },
];

export const accountPages: IAdministrationPageCard[] = [
  {
    description: 'Maintain your account settings such as address, contact information, time zones.',
    icon: AccountSettings,
    iconFillingRule: [0, 1],
    link: '/administration/settings',
    permission: 'accounts',
    permissionTypes: ['read', 'edit'],
    title: 'Account settings',
  },
  {
    description: 'View and change your current plan.',
    icon: Plan,
    iconFillingRule: [0],
    link: '/administration/plan',
    permission: 'plan_and_billing',
    permissionTypes: ['read'],
    title: 'Plan and billing',
  },
  {
    description: 'View your invoices.',
    icon: Invoices,
    iconFillingRule: [0],
    link: '/administration/invoices',
    permission: 'invoices',
    permissionTypes: ['read'],
    title: 'Invoices',
  },
];
